<template>
  <div class="unfinishedRounds">
    <!-- 未连接钱包 begin -->
    <div
      class="no-data"
      v-if="connectedStatus==0"
    >
      <div class="no-data-desc">{{$t('menu.connectYourWallet')}}</div>
      <div class="no-data-desc">{{$t('menu.starsThisRound')}}</div>
      <button
        class="no-data-btn"
        @click="openDialog(connectedStatus,1)"
      >{{$t('menu.connectWallet')}}</button>
    </div>
    <!-- 未连接钱包 end -->
    <!-- 没有数据 begin -->
    <div
      class="no-data"
      v-if="connectedStatus==1&&isRoundsData==false"
    >
      <div class="no-data-desc">{{$t('menu.noExchanged')}}</div>
      <router-link :to="{name:'ExchangeLuckyStars',path:'/exchangeLuckyStars'}" class="no-data-btn">{{$t('menu.toExchange')}}</router-link>
    </div>
    <!-- 没有数据 end -->
    <!-- 有申购数据 begin -->
    <div v-if="connectedStatus==1&&isRoundsData">
      <div class="box-draw-prize">
        <div class="prize-head">
          <div class="head-one">
            <div class="left"><span>#{{currentLotteryId}}</span>| {{$t('menu.drawAPrize')}}<span>{{formatTimeToType1(viewLotteryList[currentCurrency].endTime)}}</span></div>
            <div class="prev-num-p"><i>{{currentPage}}</i><span>/{{totalPage}}</span></div>
          </div>
          <div class="head-two">
            <div class="left">
              <i>{{count}}</i>
              <span v-if="seconds==0">{{$t('menu.inTheLottery')}}</span>
              <span v-if="seconds>0">{{$t('menu.upcomingLottery')}}</span>
            </div>
            <div class="prev-img-p">
              <span @click="getPgage(0)"><img
                  src="../../../../../../public/images/lukyStar/icon-left.png"
                  alt=""
                ></span>
              <span @click="getPgage(1)"><img
                  src="../../../../../../public/images/lukyStar/icon-right.png"
                  alt=""
                ></span>
            </div>
          </div>
        </div>
        <div class="prize-box">
          <div class="title">{{$t('menu.myNumber')}}</div>
          <div class="num">{{currentTicketNumber}}</div>
        </div>
      </div>
      <div class="prize-container">
        <div class="p-name">{{$t('menu.prizePool')}}</div>
        <div class="p-num">${{conversionToUsdt(viewLotteryList[currentCurrency].amountCollectedInCake)}}</div>
        <div class="p-tip"><i>{{viewLotteryList[currentCurrency].amountCollectedInCake}}</i><span> {{currencyList[currentCurrency]}}</span></div>
        <div class="p-desc">{{$t('menu.prizeDescTip')}}</div>
        <div class="desc-content">
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop1')}}</div>
            <div class="num"><span>{{viewLotteryList[currentCurrency].rewardsBreakdownAmount[0]}}</span> {{currencyList[currentCurrency]}}</div>
            <div class="tip">${{conversionToUsdt(viewLotteryList[currentCurrency].rewardsBreakdownAmount[0])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop2')}}</div>
            <div class="num"><span>{{viewLotteryList[currentCurrency].rewardsBreakdownAmount[1]}}</span> {{currencyList[currentCurrency]}}</div>
            <div class="tip">${{conversionToUsdt(viewLotteryList[currentCurrency].rewardsBreakdownAmount[1])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop3')}}</div>
            <div class="num"><span>{{viewLotteryList[currentCurrency].rewardsBreakdownAmount[2]}}</span> {{currencyList[currentCurrency]}}</div>
            <div class="tip">${{conversionToUsdt(viewLotteryList[currentCurrency].rewardsBreakdownAmount[2])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop4')}}</div>
            <div class="num"><span>{{viewLotteryList[currentCurrency].rewardsBreakdownAmount[3]}}</span> {{currencyList[currentCurrency]}}</div>
            <div class="tip">${{conversionToUsdt(viewLotteryList[currentCurrency].rewardsBreakdownAmount[3])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop5')}}</div>
            <div class="num"><span>{{viewLotteryList[currentCurrency].rewardsBreakdownAmount[4]}}</span> {{currencyList[currentCurrency]}}</div>
            <div class="tip">${{conversionToUsdt(viewLotteryList[currentCurrency].rewardsBreakdownAmount[4])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop6')}}</div>
            <div class="num"><span>{{viewLotteryList[currentCurrency].rewardsBreakdownAmount[5]}}</span> {{currencyList[currentCurrency]}}</div>
            <div class="tip">${{conversionToUsdt(viewLotteryList[currentCurrency].rewardsBreakdownAmount[5])}}</div>
          </div>
          <div class="list">
            <div class="title red">{{$t('menu.incinerate')}}</div>
            <div class="num"><span>{{viewLotteryList[currentCurrency].treasuryAmount}}</span> {{currencyList[currentCurrency]}}</div>
            <div class="tip">${{conversionToUsdt(viewLotteryList[currentCurrency].treasuryAmount)}}</div>
          </div>
        </div>

      </div>
    </div>
    <!-- 有申购数据 end -->
    <Star-Wallet
      v-if="dialogShow"
      :id="0"
      @transfer="openDialog"
    ></Star-Wallet>
  </div>
</template>

<script>
import '../../styles/common.css';
import StarWallet from '../../common/StarWallet.vue';

const mLottery = require('../../../../../utils/mLottery')

export default {
  components: { StarWallet },
  data() {
    return {
      connectedStatus: 0,//未连接=0;连接成功=1
      dialogShow: false,
      isRoundsData: false,//是否有申购数据
      prizeNum: "732", //第n次开奖
      currentPage: 0,//当前页
      totalPage: 0, //总页数

      lotteryId:0,
      currentCurrency:0,//0是STAR 1是ZEED 2是YEED
      currencyList:['STAR','ZEED','YEED'],
      currentTicketNumber:'',// 当前彩票号码
      currentLotteryId:'',// 当局lotteryId
      viewLotteryList:[
        {
          endTime: "0", // 结束投注时间
          amountCollectedInCake:0, // 本轮奖池的币
          cakePerBracket: [], // 开奖后，每个挡位中奖币数量
          treasuryFee: "0", // 打入黑洞的比例，x/10000
          rewardsBreakdownAmount:[],
          treasuryAmount:0,
        },
        {
          endTime: "0", // 结束投注时间
          amountCollectedInCake:0, // 本轮奖池的币
          cakePerBracket: [], // 开奖后，每个挡位中奖币数量
          treasuryFee: "0", // 打入黑洞的比例，x/10000
          rewardsBreakdownAmount:[],
          treasuryAmount:0,
        },
        {
          endTime: "0", // 结束投注时间
          amountCollectedInCake:0, // 本轮奖池的币
          cakePerBracket: [], // 开奖后，每个挡位中奖币数量
          treasuryFee: "0", // 打入黑洞的比例，x/10000
          rewardsBreakdownAmount:[],
          treasuryAmount:0,
        }
      ],
      viewUserInfoList:[

      ],
      priceList:[0,0,0],
      formatTimeToType1:this.mLottery.formatTimeToType1,

      count: '', //倒计时
      seconds: 0, // 倒计时秒数
      timeId:null,
      
    }
  },
  watch:{
    // 监听申购数量变化
    viewUserInfoList(newVal){
      let viewUserInfoListLength = newVal.length
      if(viewUserInfoListLength>0){
        this.totalPage = viewUserInfoListLength
        this.currentPage = 1
        this.isRoundsData = true
      }
    },
    currentPage(newValu){
      console.log('currentPage =====================',newValu)
      if(newValu>0 && newValu<=this.totalPage){
        let index = newValu-1
        this.currentTicketNumber = this.viewUserInfoList[index]['ticketNumber']
        this.currentLotteryId = this.viewUserInfoList[index]['lotteryId']
        this.currentCurrency = this.viewUserInfoList[index]['currentCurrency']

        // 获取当前时间
        let endTime = this.viewLotteryList[this.currentCurrency].endTime
        let time = parseInt(new Date().getTime() / 1000) + '';
        if(endTime>0&&time<endTime){
          this.seconds = endTime - time
          clearTimeout(this.timeId)
          this.Time() //调用定时器
        }
        
      }
    }
  },
  created: function () {
    var address = localStorage.getItem('address')
    if (address) {
      this.connectedStatus = 1;
    }

    // 获取Star价格
    mLottery.default.getStarAddrPrice().then(starPrice=>{
      this.priceList[0] = starPrice
    })
    // 获取Zeed价格
    mLottery.default.getZeedAddrrPrice().then(zeedPrice=>{
      this.priceList[1] = zeedPrice
    })
    // 获取Yeed价格
    mLottery.default.getYeedAddrPrice().then(yeedPrice=>{
      this.priceList[2] = yeedPrice
    })

    // 获取数据
    let that = this
    for (let currentCurrency = 0; currentCurrency < 3; currentCurrency++) {
      mLottery.default.switchContract(currentCurrency)
      let contract ={ ...mLottery.default }

      // 当前正在进行的lotteryId
      contract.viewCurrentLotteryId().then(lotteryId=>{
        console.log(lotteryId)
        that.lotteryId = lotteryId
        // 根据lotteryId获得整一局的信息
        contract.viewLottery(lotteryId).then(viewLotteryRes=>{
          let viewLottery = Object.assign({}, viewLotteryRes)
          viewLottery.amountCollectedInCake = contract.amountConversion(viewLottery.amountCollectedInCake)
          let rewardsBreakdownAmount = []
          for (let index = 0; index < viewLottery.rewardsBreakdown.length; index++) {
            rewardsBreakdownAmount[index] = Math.floor((viewLottery.amountCollectedInCake * viewLottery.rewardsBreakdown[index] /10000) * 1000000) / 1000000; 
          }
          viewLottery.rewardsBreakdownAmount = rewardsBreakdownAmount
          viewLottery.treasuryAmount =  Math.floor((viewLottery.amountCollectedInCake * viewLottery.treasuryFee /10000) * 1000000) / 1000000; 

          that.viewLotteryList[currentCurrency] = viewLottery
          console.log(that.viewLotteryList)
        })
        // 查看用户已购买彩票
        contract.viewUserInfoForLotteryId(lotteryId).then(viewUserInfoRes=>{
          let viewUserInfo = Object.assign({}, viewUserInfoRes)
          let count = viewUserInfo[3]
          console.log('viewUserInfoRes count',count)
          for (let i = 0; i < count; i++) {
            let ticketNumber = contract.formatTicketNumberForResponse(viewUserInfo[1][i]) // 格式化彩票号码
            that.viewUserInfoList.push({
              lotteryId:lotteryId,
              currentCurrency:currentCurrency,
              ticketId:viewUserInfo[0][i],
              ticketNumber:ticketNumber.join(""),
              status:viewUserInfo[2][i]
            })
          }
          
          console.log(that.viewUserInfoList)

        })
      })
      
    }
    
  },
  mounted() {
    this.Time() //调用定时器
  },
  methods: {
    getPgage(type) {//下一页，上一页功能
      console.log(type);
      if (type == 0 && this.currentPage>1) {
        this.currentPage = this.currentPage - 1;
      }
      if (type == 1 && this.currentPage< this.totalPage ) {
        this.currentPage = this.currentPage + 1;
      }

    },
    openDialog(connectStatus, type) {//打开/关闭钱包弹窗
      if (type == 1) {//type=1 打开钱包弹窗，type=2 关闭钱包弹窗
        this.dialogShow = true;
        console.log(connectStatus, '==', type);
      }
      if (type == 2) {
        this.dialogShow = false;
        this.connectedStatus = connectStatus;
      }
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60))
      d = d < 10 ? "0" + d : d
      let h = parseInt(this.seconds / (60 * 60) % 24);
      h = h < 10 ? "0" + h : h
      let m = parseInt(this.seconds / 60 % 60);
      m = m < 10 ? "0" + m : m
      let s = parseInt(this.seconds % 60);
      s = s < 10 ? "0" + s : s
      this.count = d + this.$t('menu.day') + h + ':' + m + ':' + s 
      if(this.seconds==0){
        clearTimeout(this.timeId)
      }
    },
    //定时器没过1秒参数减1
    Time() {
        this.timeId = setInterval(() => {
            this.seconds -= 1
            if(this.seconds<0){
              this.seconds = 0
            }
            this.countDown()
        }, 1000)
    },
    // 转换位usdt 
    conversionToUsdt(_amount){
      return Math.floor((_amount * this.priceList[this.currentCurrency]) * 1000000) / 1000000; 
    }
  }
}
</script>

<style>
</style>